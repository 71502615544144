import { EntityType } from '../entities';

export enum ConfigurationId {
  quickbooks = 'quickbooks',
}

export enum MapId {
  postMap = 'post-map',
  pageMap = 'page-map',
  navMap = 'nav-map',
}

export const paths = new Map<EntityType, string>([
  [EntityType.User, 'users'],
  [EntityType.UserRequest, 'admin/all/requests/all/user'],
  [EntityType.AdminBookingChange, 'admin/all/requests/all/booking'],
  [EntityType.Campaign, 'marketing/all/campaigns'],
  [EntityType.CampaignRun, 'marketing/all/campaign-runs'],
  [EntityType.CustomerList, 'marketing/all/lists'],
  [EntityType.CustomerTag, 'marketing/all/tags'],
  [EntityType.Segment, 'marketing/all/segments'],
  [EntityType.Product, 'catalog/all/products'],
  [EntityType.Coupon, 'catalog/all/coupons'],
  [EntityType.PromoCode, 'catalog/all/promocodes'],
  [EntityType.SKU, 'catalog/all/skus'],
  [EntityType.Royalty, 'accounting/all/royalties'],
  [EntityType.Order, 'accounting/all/orders'],
  [EntityType.Subscription, 'accounting/all/subscriptions'],
  [EntityType.Charge, 'accounting/all/charges'],
  [EntityType.Course, 'library/all/courses'],
  [EntityType.Flow, 'library/all/flows'],
  [EntityType.CourseProgress, '/courses'],
  [EntityType.ChallengeProgress, '/challenges'],
  [EntityType.Content, '/PID/content'],
  [EntityType.Challenge, 'library/all/challenges'],
  [EntityType.Asset, 'library/all/assets'],
  [EntityType.Page, 'library/all/pages'],
  [EntityType.Email, 'library/all/emails'],
  [EntityType.Form, 'library/all/forms'],
  [EntityType.EmailTest, '/tests'],
  [EntityType.EmailParent, 'library/all/emails/PID'],
  [EntityType.HTML, 'library/all/html'],
  [EntityType.PageTemplate, 'library/all/templates'],
  [EntityType.PageContent, 'library/all/content'],
  [EntityType.PageGlobal, 'library/all/globals'],
  [EntityType.Tag, 'library/all/tags'],
  [EntityType.Post, 'library/all/posts'],
  [EntityType.Workflow, 'library/all/workflows'],
  [EntityType.Map, 'library/all/maps'],
  [EntityType.Customer, 'customers'],
  [EntityType.CX, 'cx'],
  [EntityType.CartItem, '/cart'],
  [EntityType.PaymentMethod, '/payment-methods'],
  [EntityType.Token, '/tokens'],
  [EntityType.SetupIntent, '/requests/all/setup-intents'],
  [EntityType.MinimizedOrder, '/orders'],
  [EntityType.MinimizedBooking, '/bookings'],
  [EntityType.BookingChange, '/requests/all/booking'],
  [EntityType.Booking, 'scheduling/all/bookings'],
  [EntityType.OrderRequest, '/requests/all/order'],
  [EntityType.Submittal, '/requests/all/form'],
  [EntityType.Checkout, '/checkouts'],
  [EntityType.Payment, '/payments'],
  [EntityType.Profile, '/profile'],
  [EntityType.QuickbooksConfiguration, 'configuration'],
  [EntityType.Configuration, 'configuration'],
  [EntityType.ThemeConfiguration, 'configuration'],
  [EntityType.NetworkingConfiguration, 'configuration'],
  [EntityType.AddonConfiguration, 'configuration'],
  [EntityType.AccountingConfiguration, 'configuration'],
  [EntityType.CustomerParent, 'customers/PID'],
  [EntityType.CXParent, 'cx/PID'],
  [EntityType.Space, 'community'],
  [EntityType.SpaceParent, 'community/PID'],
  [EntityType.SpaceGrandParent, 'community/GID'],
  [EntityType.Thread, '/threads'],
  [EntityType.ThreadParent, '/threads/PID'],
  [EntityType.Comment, '/comments'],
  [EntityType.CommentMap, '/comments'],
  [EntityType.DraftThread, '/threads'],
  [EntityType.UserAsset, '/assets'],
  [EntityType.UserCordonedComment, '/comments'],
  [EntityType.CordonedComment, 'community/cordoned/comments'],
  [EntityType.UserCommentModerationMap, '/comments'],
  [EntityType.CommunityCommentModerationMap, 'community/cordoned/comments'],
  [EntityType.CordonedThread, 'community/cordoned/threads'],
  [EntityType.UserThreadModerationMap, '/threads'],
  [EntityType.CommunityThreadModerationMap, 'community/cordoned/threads'],
  [EntityType.Affiliate, 'affiliates'],
  [EntityType.AffiliateParent, 'affiliates/PID'],
  [EntityType.AffiliateSale, '/sales'],
  [EntityType.AffiliatePayout, '/payouts'],
  [EntityType.UserAvailabilityGlobal, 'scheduling/all/availability'],
]);

export const indexes = new Map<EntityType, string>([
  [EntityType.Course, 'course'],
  [EntityType.Challenge, 'challenge'],
  [EntityType.MinimizedOrder, 'parentId'],
  [EntityType.MinimizedBooking, 'parentId'],
  [EntityType.Space, 'space'],
  [EntityType.SpaceParent, 'space'],
  [EntityType.Thread, 'thread'],
  [EntityType.ThreadParent, 'thread'],
  [EntityType.Affiliate, 'affiliate'],
  [EntityType.AffiliateParent, 'affiliate'],
  [EntityType.EmailTest, 'emailTest'],
  [EntityType.EmailParent, 'email'],
  [EntityType.CustomerParent, 'customer'],
]);

export function getAccountingOrdersByCustomerIdPath(tenantId: string): string {
  return `tenants/${tenantId}/` + paths.get(EntityType.Order);
}

export function getAccountingSubscriptionsByCustomerIdPath(
  tenantId: string
): string {
  return `tenants/${tenantId}/` + paths.get(EntityType.Subscription);
}

export function getPath(
  tenantId: string,
  entityType: EntityType,
  parentEntityType?: EntityType,
  parentEntityId?: string,
  grandParentEntityType?: EntityType,
  grandParentEntityId?: string
): string {
  const grandParentPath = grandParentEntityType
    ? paths.get(grandParentEntityType)
    : '';
  const parentPath = parentEntityType ? paths.get(parentEntityType) : '';
  let path = paths.get(entityType);

  if (parentPath && parentPath !== '') {
    path = parentPath + path;
    path = path.replace('PID', parentEntityId ?? '');
  }

  if (grandParentPath && grandParentPath !== '') {
    path = grandParentPath + path;
    path = path.replace('GID', grandParentEntityId ?? '');
  }

  if (!path) {
    return '';
  }

  // append tenantId to path
  if (tenantId && tenantId !== '') {
    path = 'tenants/' + tenantId + '/' + path;
  }

  return path;
}

export function getParentEntityIndex(entityType: EntityType): string {
  return indexes.get(entityType) ?? '';
}

export function getOrdersByCustomerIdIndex(): string {
  return 'customerId';
}

export function getSubscriptionsByCustomerIdIndex(): string {
  return 'customerId';
}
