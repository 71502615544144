/** Order */

export const loadOrderType = '[my-account/order] load order';
export const loadOrderSuccessType = '[my-account/order] load order success';
export const loadOrderFailureType = '[my-account/order] load order failure';
export const selectOrderType = '[my-account/order] select order';

/** Subscription */

export const loadSubscriptionType =
  '[my-account/subscription] load subscription';
export const loadSubscriptionSuccessType =
  '[my-account/subscription] load subscription success';
export const loadSubscriptionrFailureType =
  '[my-account/subscription] load subscription failure';
export const selectSubscriptionType =
  '[my-account/subscription] select subscription';
