export enum EntityType {
  User = 'User',
  UserRequest = 'UserRequest',
  Segment = 'Segment',
  Campaign = 'Campaign',
  CampaignRun = 'CampaignRun',
  Affiliate = 'Affiliate',
  AffiliateParent = 'AffiliateParent',
  AffiliateSale = 'AffiliateSale',
  AffiliatePayout = 'AffiliatePayout',
  Purchasable = 'Purchasable',
  Course = 'Course',
  Content = 'Content',
  Product = 'Product',
  Section = 'Section',
  SKU = 'Sku',
  Asset = 'Asset',
  CourseProgress = 'CourseProgress',
  PageContent = 'PageContent',
  PageTemplate = 'PageTemplate',
  PageGlobal = 'PageGlobal',
  Page = 'Page',
  Email = 'Email',
  Form = 'Form',
  Submittal = 'Submittal',
  EmailTest = 'EmailTest',
  EmailParent = 'EmailParent',
  Slot = 'Slot',
  Cart = 'Cart',
  CartItem = 'CartItem',
  Address = 'Address',
  Token = 'Token',
  Source = 'Source',
  PaymentMethod = 'PaymentMethod',
  CX = 'CX',
  CXParent = 'CXParent',
  Customer = 'Customer',
  Tenant = 'Tenant',
  CustomerTag = 'CustomerTag',
  CustomerList = 'CustomerList',
  CustomerParent = 'CustomerParent',
  OrderRequest = 'OrderRequest',
  Order = 'Order',
  Subscription = 'Subscription',
  MinimizedOrder = 'MinimizedOrder',
  MinimizedSubscription = 'MinimizedSubscription',
  SetupIntent = 'SetupIntent',
  Coupon = 'Coupon',
  PromoCode = 'PromoCode',
  Challenge = 'Challenge',
  ChallengeProgress = 'ChallengeProgress',
  ChallengeSection = 'ChallengeSection',
  Flow = 'Flow',
  FlowSection = 'FlowSection',
  QuickbooksConfiguration = 'QuickbooksConfiguration',
  StripeConfiguration = 'StripeConfiguration',
  ActiveCampaignConfiguration = 'ActiveCampaignConfiguration',
  MailerConfiguration = 'MailerConfiguration',
  VimeoConfiguration = 'VimeoConfiguration',
  ZoomConfiguration = 'ZoomConfiguration',
  GoogleConfiguration = 'GoogleConfiguration',
  SlackConfiguration = 'SlackConfiguration',
  Sentiment = 'Sentiment',
  ChallengeEvent = 'ChallengeEvent',
  Charge = 'Charge',
  QuickbooksAccount = 'QuickbooksAccount',
  QuickbooksVendor = 'QuickbooksVendor',
  Rendering = 'Rendering',
  Royalty = 'Royalty',
  HTML = 'Html',
  Checkout = 'Checkout',
  Payment = 'Payment',
  Profile = 'Profile',
  NetworkingConfiguration = 'Configuration',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ThemeConfiguration = 'Configuration',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  AddonConfiguration = 'Configuration',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  AccountingConfiguration = 'Configuration',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Configuration = 'Configuration',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CommunityConfiguration = 'Configuration',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SchedulingConfiguration = 'Configuration',
  Post = 'Post',
  Map = 'Map',
  Tag = 'Tag',
  Space = 'Space',
  SpaceParent = 'SpaceParent',
  SpaceGrandParent = 'SpaceGrandParent',
  Thread = 'Thread',
  ThreadParent = 'ThreadParent',
  DraftThread = 'DraftThread',
  Comment = 'Comment',
  CommentMap = 'CommentMap',
  UserAsset = 'UserAsset',
  CordonedComment = 'CordonedComment',
  UserCordonedComment = 'UserCordonedComment',
  UserCommentModerationMap = 'UserCommentModerationMap',
  CommunityCommentModerationMap = 'CommentModerationMap',
  CordonedThread = 'CordonedThread',
  UserCordonedThread = 'UserCordonedThread',
  UserThreadModerationMap = 'UserThreadModerationMap',
  CommunityThreadModerationMap = 'ThreadModerationMap',
  Workflow = 'Workflow',
  UserAvailabilityGlobal = 'UserAvailabilityGlobal',
  Booking = 'Booking',
  MinimizedBooking = 'MinimizedBooking',
  BookingChange = 'BookingChange',
  AdminBookingChange = 'AdminBookingChange',
}

export const EntityToPrefixMap = new Map<EntityType, string>([
  [EntityType.UserRequest, 'u'],
  [EntityType.User, 'u'],
  [EntityType.Flow, 'fl'],
  [EntityType.FlowSection, 'fls'],
  [EntityType.Affiliate, 'af'],
  [EntityType.Course, 'cr'],
  [EntityType.Content, 'co'],
  [EntityType.Product, 'pd'],
  [EntityType.Section, 'sc'],
  [EntityType.SKU, 'sk'],
  [EntityType.Asset, 'as'],
  [EntityType.CourseProgress, 'crp'],
  [EntityType.PageContent, 'pc'],
  [EntityType.PageTemplate, 'pt'],
  [EntityType.PageGlobal, 'pg'],
  [EntityType.Page, 'pa'],
  [EntityType.Email, 'em'],
  [EntityType.EmailTest, 'emt'],
  [EntityType.Form, 'fm'],
  [EntityType.Submittal, 'fms'],
  [EntityType.Rendering, 're'],
  [EntityType.Slot, 'sl'],
  [EntityType.Cart, 'ca'],
  [EntityType.Address, 'ad'],
  [EntityType.Token, 'tk'],
  [EntityType.Source, 'sr'],
  [EntityType.PaymentMethod, 'pm'],
  [EntityType.Customer, 'cu'],
  [EntityType.CX, 'cu'],
  [EntityType.Charge, 'ch'],
  [EntityType.OrderRequest, 'or'],
  [EntityType.Order, 'od'],
  [EntityType.SetupIntent, 'si'],
  [EntityType.Coupon, 'cp'],
  [EntityType.PromoCode, 'pc'],
  [EntityType.Challenge, 'cl'],
  [EntityType.ChallengeProgress, 'clp'],
  [EntityType.ChallengeEvent, 'cle'],
  [EntityType.ChallengeSection, 'cls'],
  [EntityType.QuickbooksConfiguration, 'iqc'],
  [EntityType.StripeConfiguration, 'isc'],
  [EntityType.ActiveCampaignConfiguration, 'iacc'],
  [EntityType.Sentiment, 'st'],
  [EntityType.ChallengeEvent, 'cle'],
  [EntityType.Configuration, ''],
  [EntityType.Post, 'po'],
  [EntityType.Map, ''],
  [EntityType.Tag, 'tg'],
  [EntityType.Space, 'sp'],
  [EntityType.Thread, 'th'],
  [EntityType.DraftThread, 'th'],
  [EntityType.Comment, 'cm'],
  [EntityType.UserAsset, 'as'],
  [EntityType.Workflow, 'wf'],
  [EntityType.CustomerTag, 'ct'],
  [EntityType.CustomerList, 'cl'],
  [EntityType.Campaign, 'cn'],
  [EntityType.CampaignRun, 'cnr'],
  [EntityType.Segment, 'sg'],
  [EntityType.UserAvailabilityGlobal, 'uag'],
  [EntityType.Booking, 'bk'],
  [EntityType.MinimizedBooking, 'bk'],
  [EntityType.BookingChange, 'bkc'],
  [EntityType.AdminBookingChange, 'bkc'],
]);

export const CookieAffiliateCode = '__sidkik_a_code';
