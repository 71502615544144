export enum OrderStatus {
  Created = 'created',
  Processing = 'processing',
  Errored = 'error',
  Paid = 'paid',
  PartiallyPaid = 'partially_paid',
}

export enum ChargeStatus {
  Pending = 'pending',
  Paid = 'paid',
  Refunded = 'refunded',
  PartialRefunded = 'partial_refunded',
  Failed = 'failed',
  Errored = 'errored',
  Cancelled = 'cancelled',
}
