import { trigger, transition, style, animate } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  APP_CONFIG,
  AppConfig,
  GlobalFacade,
  NavigationType,
  STRIPE_ENABLED,
  StaticSlug,
} from '@sidkik/global';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  filter,
  map,
} from 'rxjs';
import { ClientLayoutFacade } from '../../+state/client-layout.facade';
import { MapFacade } from '../../+state/map/map.facade';
import { NavigationEntry } from '@sidkik/db';
import { isPlatformBrowser } from '@angular/common';
import { NotificationService } from '@sidkik/ui';
import { Router } from '@angular/router';

@Component({
  selector: 'sidkik-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
    trigger('AnimationTrigger2', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ContainerComponent implements OnInit, OnDestroy {
  condensedlogoUrl = this.appConfig.branding?.smallLogo;
  logoUrl = this.appConfig.branding?.logo;
  siteName = this.appConfig.branding?.siteName;
  loggedIn$!: Observable<boolean>;
  cartCount$!: Observable<number>;
  isOffCanvasMenu = false;

  emptyGroup = '_EMPTY_';

  now = Date.now();

  headerNavItems$: BehaviorSubject<NavigationEntry[]> = new BehaviorSubject<
    NavigationEntry[]
  >([]);
  footerNavItems$: BehaviorSubject<
    { group: string; items: NavigationEntry[] }[]
  > = new BehaviorSubject<{ group: string; items: NavigationEntry[] }[]>([]);
  navMapSub!: Subscription;
  fNavSub!: Subscription;
  hNavSub!: Subscription;
  breadcrumbTrail$!: Observable<any>;
  debugCounter = 0;
  debugStartOverCounter = 0;
  headerNavType$: BehaviorSubject<NavigationType> =
    new BehaviorSubject<NavigationType>(NavigationType.Full);
  footerNavType$: BehaviorSubject<NavigationType> =
    new BehaviorSubject<NavigationType>(NavigationType.Full);

  navTypes = NavigationType;

  myActivitiesName$ = new BehaviorSubject<string>('My Activities');

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject('APP_VERSION') public version: string,
    @Inject(STRIPE_ENABLED) public stripeEnabled: boolean,
    @Inject(PLATFORM_ID) private platformId: any,
    private layoutFacade: ClientLayoutFacade,
    private mapFacade: MapFacade,
    private notificationService: NotificationService,
    private globalFacade: GlobalFacade,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.navMapSub?.unsubscribe();
    this.fNavSub?.unsubscribe();
    this.hNavSub?.unsubscribe();
  }

  ngOnInit() {
    this.breadcrumbTrail$ = this.layoutFacade.breadcrumbTrail$;
    this.loggedIn$ = this.layoutFacade.user$.pipe(
      map((u) => u !== null && u !== undefined)
    );
    this.cartCount$ = this.layoutFacade.cartItems$.pipe(
      map((items) => (items && items.length ? items.length : 0))
    );
    this.navMapSub = combineLatest([
      this.mapFacade.navMap$,
      this.globalFacade.footerNavigation$,
    ]).subscribe(([navMap, footerNav]) => {
      if (navMap) {
        if (
          navMap.data.myActivitiesOverride &&
          navMap.data.myActivitiesOverride !== ''
        ) {
          this.myActivitiesName$.next(navMap.data.myActivitiesOverride);
        }
        this.headerNavItems$.next(navMap.data?.header ?? []);
        // if footer nav is set to minimal, filter only privacy and terms
        if (footerNav === NavigationType.Minimal) {
          this.footerNavItems$.next(
            this.getFooterNavItems(
              navMap.data?.footer?.filter(
                (n) =>
                  n.url === '/' + StaticSlug.PrivacyPolicy ||
                  n.url === '/' + StaticSlug.TermsOfUse
              ) ?? []
            )
          );
          return;
        }
        this.footerNavItems$.next(
          this.getFooterNavItems(navMap.data?.footer ?? [])
        );
        return;
      }
      this.headerNavItems$.next([]);
      this.footerNavItems$.next([]);
    });
    // this is a hack to get around the change detection issue with the nav type
    this.fNavSub = this.globalFacade.footerNavigation$
      .pipe(filter((n) => !!n))
      .subscribe((navType) => {
        this.footerNavType$.next(navType ?? NavigationType.Full);
        this.cdr.detectChanges();
      });
    this.hNavSub = this.globalFacade.headerNavigation$
      .pipe(filter((n) => !!n))
      .subscribe((navType) => {
        this.headerNavType$.next(navType ?? NavigationType.Full);
        this.cdr.detectChanges();
      });
  }

  /** Returns true if the given url looks external */
  isExternal(url: string): boolean {
    return /^http(?:s)?:\/{2}\S+$/.test(url) || /^mailto:.*/.test(url);
  }

  enableDebug() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.debugCounter > 5) {
        localStorage.setItem('ROARR_LOG', 'true');
        this.notificationService.showInfo(
          'Debugging enabled',
          'See console. Must refresh page to take effect.'
        );
      }
      this.debugCounter++;
      return;
    }
  }

  enableDebugStartOver() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.debugStartOverCounter > 10) {
        this.notificationService.showInfo(
          'Starting Over',
          'Clearing out local database and reloading page.'
        );
        // navigate to '/debug-flush' to clear out the local database
        this.router.navigate(['/debug-flush']);
      }
      this.debugStartOverCounter++;
      return;
    }
  }

  // group footer items by group
  getFooterNavItems(ne: NavigationEntry[]) {
    const groups = ne.reduce((acc, item) => {
      if (item.group?.trim() === '' || item.group === undefined) {
        if (!acc[this.emptyGroup]) {
          acc[this.emptyGroup] = [];
        }
        acc[this.emptyGroup].push(item);
      }
      if (item.group) {
        if (!acc[item.group]) {
          acc[item.group] = [];
        }
        acc[item.group].push(item);
      }
      return acc;
    }, {} as { [key: string]: NavigationEntry[] });
    return Object.keys(groups).map((key) => {
      return {
        group: key,
        items: groups[key],
      };
    });
  }

  hideOffCanvasMenu() {
    this.isOffCanvasMenu = false;
  }

  toggleOffCanvasMenu() {
    this.isOffCanvasMenu = !this.isOffCanvasMenu;
  }
}
