import { Component, Inject, Input } from '@angular/core';
import { AssetProperties } from '@sidkik/db';
import {
  AppConfig,
  APP_CONFIG,
  AssetService as GlobalAssetService,
  AssetSize,
} from '@sidkik/global';

enum AssetType {
  image = 'image',
  pdf = 'pdf',
  video = 'video',
  vimeo = 'vimeo',
  unknown = 'unknown',
}

const sizeList = [
  AssetSize.small,
  AssetSize.medium,
  AssetSize.large,
  AssetSize.xlarge,
  AssetSize.xxlarge,
];
const sizeToWidth = (size: AssetSize) => {
  switch (size) {
    case AssetSize.small:
      return '250';
    case AssetSize.medium:
      return '480';
    case AssetSize.large:
      return '1000';
    case AssetSize.xlarge:
      return '1800';
    case AssetSize.xxlarge:
      return '2400';
    default:
      return '480';
  }
};

const sizeToFactor = (size: AssetSize) => {
  switch (size) {
    case AssetSize.small:
      return '1x';
    case AssetSize.medium:
      return '2x';
    case AssetSize.large:
      return '3x';
    default:
      return '1x';
  }
};

@Component({
  selector: 'sidkik-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class AssetComponent {
  @Input() asset: AssetProperties | undefined;
  @Input() thumbOnly = false;
  @Input() isBackground = false;
  @Input() isResponsive = false;
  @Input() preset = '';
  @Input() class!: string;
  @Input() size: AssetSize | string = AssetSize.medium;
  @Input() customRender = '';
  sizes = AssetSize;
  assetTypes = AssetType;
  initials = '';

  videoOptions = {
    query: { title: false, portrait: false, byline: false, responsive: true },
  };

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private globalAssetService: GlobalAssetService
  ) { }

  get assetType(): AssetType {
    const type = this.asset?.data?.type ?? '';
    switch (true) {
      case type.indexOf('image') !== -1:
        return AssetType.image;
      case type.indexOf('application') !== -1:
        return AssetType.pdf;
      case type.indexOf('video') !== -1: {
        if (this.asset?.integrations?.vimeo) {
          return AssetType.vimeo;
        }
        return AssetType.video;
      }
      default:
        return AssetType.unknown;
    }
  }

  getPDF(asset: AssetProperties): string {
    return '';
  }

  getVideo(asset: AssetProperties): string {
    return '';
  }

  getVideoThumbnail(): string {
    const videoThumb = this.asset?.data?.thumbnails?.find(
      (thumb) => thumb.thumbnailSize === AssetSize.large
    );
    if (!videoThumb) {
      return '';
    }
    let url = this.globalAssetService.getAssetImageURL(
      videoThumb.key ?? '',
      videoThumb.token ?? ''
    );
    if (this.preset !== '') {
      url += `&p=${this.preset}`;
    }
    if (this.customRender !== '') {
      url += `${this.customRender}`;
    }
    return url;
  }

  getImageURL(): string {
    if (!this.asset) {
      return '';
    }
    // conversion asset from before tenancy
    if (
      !this.asset.data.token &&
      this.asset.data.thumbnails &&
      this.asset.data.thumbnails.length
    ) {
      const thumb = this.asset?.data?.thumbnails?.find(
        (thumb) => thumb.thumbnailSize === AssetSize.large
      );
      if (!thumb) {
        return '';
      }
      let url = this.globalAssetService.getAssetImageURL(
        thumb.key ?? '',
        thumb.token ?? ''
      );
      if (this.preset !== '') {
        url += `&p=${this.preset}`;
      }
      if (this.customRender !== '') {
        url += `${this.customRender}`;
      }
      return url;
    }
    // if no token, then it is a public asset - like shared starter logo images
    if (!this.asset.data.token) {
      return this.asset.data.url;
    }
    let url = this.globalAssetService.getAssetImageURL(
      this.asset?.data.key ?? '',
      this.asset?.data.token ?? ''
    );
    if (this.preset !== '') {
      url += `&p=${this.preset}`;
    }
    if (this.customRender !== '') {
      url += `${this.customRender}`;
    }

    return url;
  }

  getImageSrcSet(): string {
    const preset = this.preset !== '' ? `&p=${this.preset}` : '';
    const customRender = this.customRender !== '' ? this.customRender : '';
    if (this.isResponsive) {
      const imgs = sizeList.map(
        (t) =>
          `${this.globalAssetService.getAssetImageURL(
            this.asset?.data.key ?? '',
            this.asset?.data.token ?? ''
          )}${preset}${customRender}&gravity=smart&w=${sizeToWidth(
            t
          )} ${sizeToWidth(t)}w`
      );
      return imgs.join(',');
    }
    return '';
  }

  getVideoBackgroundStyle(): string {
    const videoThumb = this.asset?.data?.thumbnails?.find(
      (thumb) => thumb.thumbnailSize === AssetSize.large
    );
    if (!videoThumb) {
      return '';
    }
    const style = [];
    const preset = this.preset !== '' ? `&p=${this.preset}` : '';
    const customRender = this.customRender !== '' ? this.customRender : '';
    const factored = sizeList.map(
      (t) =>
        `url("${this.globalAssetService.getAssetImageURL(
          videoThumb.key ?? '',
          videoThumb.token ?? ''
        )}")${preset}${customRender}&gravity=smart&w=${sizeToWidth(
          t
        )} ${sizeToFactor(t)}`
    );

    style.push(`background-image: url("${this.getVideoThumbnail()}")`);
    if (this.isResponsive) {
      style.push(`background-image: image-set(${factored.join(',')})`);
      style.push(`background-image: -webkit-image-set(${factored.join(',')})`);
    }

    return style.join(';');
  }

  getBackgroundStyle(): string {
    const style = [];
    const preset = this.preset !== '' ? `&p=${this.preset}` : '';
    const customRender = this.customRender !== '' ? this.customRender : '';
    const factored = sizeList.map(
      (t) =>
        `url("${this.globalAssetService.getAssetImageURL(
          this.asset?.data.key ?? '',
          this.asset?.data.token ?? ''
        )}")${preset}${customRender}&gravity=smart&w=${sizeToWidth(
          t
        )} ${sizeToFactor(t)}`
    );

    style.push(`background-image: url("${this.getImageURL()}")`);
    if (this.isResponsive) {
      style.push(`background-image: image-set(${factored.join(',')})`);
      style.push(`background-image: -webkit-image-set(${factored.join(',')})`);
    }
    // explicit width not supported yet
    // style.push(`background-image: image-set(${explicit.join(',')})`);

    return style.join(';');
  }

  getImageAlt(): string {
    return '';
  }
}
